<template>
    <v-container class="ma-0 pa-0">
      <poll :questionID="questionID" question></poll>
    </v-container>
  </template>
    <script>
  import Poll from "@/components/poll/Poll.vue";
  
  export default {
    components: {
      Poll,
    },
  
    computed: {
      questionID() { return parseInt(this.$route.params.questionID) },
    }
  }
    </script>